&.bundle-overview {

    .lead-section {
        padding: 0;

        .page-title,
        .title {
            h1 {
                font-size: $h2-font-size;
            }
        }
    }
}

&.accommodation-book-error,
&.accommodation-book-succes,
&.accommodation-book-terms-and-conditions {
    .mini-sab {
        .container {
            .container-holder {
                display: none;
            }
        }
    }
}

&.accommodation-arrangement-overview,
&.assortiment-overview,
&.blog-post-overview {
    .bundle-overview-section {
        padding-bottom: 230px;
    }

    .footer {
        margin-top: 2rem !important;
    }
}