.gallery,
.gallery-horizontal {
    margin: 0 -1px;
    border-radius: 30px;
    overflow: hidden;

    .gallery-item {
        padding: 0 1px;
        margin: 0 0 2px 0;
    }

    .gallery-label {
        top: 1.5rem;
        padding: 4px 8px;
    }
}