.form {
    background-color: $white;
    border: none;
    box-shadow: 0 0 26px rgba($black, .25);
    border-radius: 0 0 15px 15px;
    padding: 1.5rem 1.25rem 3.5rem;

    @include media-breakpoint-up(md) {
        padding: 2rem 1.5rem 3.25rem;
    }

    .form-control:focus {
        border-color: $green;
    }

    .btn {
        @extend .btn-secondary;
    }
}