a.accommodation-list-item,
a.reset-filters-link,
a.date-range-picker-action-item-link,
a.price-carousel-item-content,
.toggle-switch a {
	text-decoration: none !important;
}

.price-carousel-item.active {
    a.price-carousel-item-content {
        color: #FFF !important;
    }
}