.top-curve,
.bottom-curve {
    position: relative;
    z-index: 3;
    
    --w3-mask-size: 800px;
    --w3-round-height: 50px;
    
    @include media-breakpoint-up(sm) {
        --w3-round-height: 80px;
        --w3-mask-size: 1200px;
    }

    @include media-breakpoint-up(lg) {
        --w3-mask-size: 2160px;

    }

    @include media-breakpoint-up(xxl) {
        --w3-mask-size: 3100px;
    }
}

.top-curve {
    margin-top: var(--w3-round-height);
    &::before {
		content: '';
		background-color: inherit;
		width: 100%;
		height: var(--w3-round-height);
		display: block;
		mask-image: url("/images/svg/round.svg");
		mask-position: center top;
		mask-size: var(--w3-mask-size);
		position: absolute;
		left: 0;
		top: calc( ( var(--w3-round-height) * -1 ) + 1px );
		
	}
}

.bottom-curve {
    margin-bottom: var(--w3-round-height);
    &::after {
        content: '';
        background-color: inherit;
        width: 100%;
        height: var(--w3-round-height);
        display: block;
        mask-image: url("/images/svg/round.svg");
        mask-position: center bottom;
        mask-size: var(--w3-mask-size);
        position: absolute;
        left: 0;
        bottom: calc( ( var(--w3-round-height) * -1 ) + 1px );
    }
}