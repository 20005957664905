// btn
.btn {
	font-weight: 500;
	border-radius: 99px;
	padding: 0.5rem 3rem 0.5rem 3rem;
	position: relative;
	line-height: normal;
	@media (max-width: 360px) {
		padding: 0.5rem 1rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	&::after {
		content: "\f054";
		font-family: "Font Awesome 5 Pro";
		font-weight: 900;
		font-size: 14px;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		margin-left: 10px;
		vertical-align: middle;
		transition: inherit;
		@media (max-width: 360px) {
			display: block;
		}
	}

	&:hover {
		&::after {
			@include prefix(transform, translateX(5px));
		}
	}

	// btn-primary
	// &.btn-primary {
	// 	&:hover {}
	// }

	// btn-outline-primary
	// &.btn-outline-primary {
	// 	&:hover {}
	// }

	// btn-secondary
	// &.btn-secondary {
	// 	&:hover {}
	// }

	// btn-outline-secondary
	// &.btn-outline-secondary {
	// 	&:hover {}
	// }
}

// card-btn
.card-btn {
	@extend .btn;
	padding: 0;
	color: $orange-dark;

	&:hover {
		color: $orange;
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	text-decoration: none;
	margin-bottom: 0 !important;
	font-weight: 500;
	font-size: 1rem;

	&::before {
		content: "\f053";
		font-size: 14px;
		position: absolute;
		left: 0;
		font-weight: 400;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-secondary;

	padding-left: 1.25rem;
	padding-right: 1.25rem;

	&::after {
		content: "\f078";
	}

	&:hover {
		&::after {
			@include prefix(transform, translateX(0));
		}
	}
}

// book-button
div.book-button {
	order: 3;
	@include media-breakpoint-down(xl) {
		order: 2;
	}

	ul.book-button {
		li {
			a {
				@extend .btn;
				@extend .btn-secondary;
				@include media-breakpoint-down(sm) {
					max-width: 140px;
					font-size: 12px;
					padding-left: 1.25rem;
					padding-right: 1.25rem;
				}

				&::after {
					display: none;
				}
			}
		}
	}
}
