// mixins

// adds vendor prefixes
@mixin prefix($property, $value) {
	$prefixes: webkit, moz, ms, o;
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;
	}
	#{$property}: $value;
}

// icon size
@mixin icon-size($size) {
	width: $size;
	height: $size;
	line-height: $size;
}

// add style to all headings tags
@mixin headings-style {
    @for $i from 1 through 6 {
        h#{$i}, .h#{$i} {
            @content;
        }
    }
}