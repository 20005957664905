.card:not(.icon) {
    border-radius: 0 0 15px 15px;
    border: none;
    box-shadow: 0 0 26px rgba($black, .25);
    @include prefix(transition, .3s);

    &:hover {
        @include prefix(transform, translateY(-.5rem));
        box-shadow: 0 0 30px rgba($black, .5);
    }


    .card-image {
        .card-img {
            aspect-ratio: 3 / 2;
        }
    }

    .card-body {
        padding: 1.5rem 1.25rem;

        @include media-breakpoint-up(md) {
            padding: 2rem 1.5rem;
        }
    }

    .card-caption {
        .card-title-link {

            .card-title {
                color: $black;
                &:hover {
                    color: $orange;
                }
            }
        }

        .card-subtitle {
            display: none;
        }

        .list-icons {
            .list-item {
                font-family: $headings-font-family;
                .icon {
                    color: $orange-dark;
                    i {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .card-buttons {
        margin-top: 1rem;
    }
}

// icons
.card.icon {
    background-color: unset;
    border: none;
    border-radius: 15px;
    max-width: 240px;
    padding: 10px;
    margin: auto;
    @include prefix(transition, .3s);

    &:hover {
        background-color: lighten($green-dark, 30%);
        cursor: pointer;

        .card-caption,
        .card-title,
        .card-image,
        .card-image-link {
            color: $white !important;
        }
    }

    .card-image {
        font-size: 50px;

        &,
        &-link {
            transition: none !important;
            color: $orange-dark !important;
        }
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
        max-width: 220px;
        margin: auto;
    }

    .card-caption {
        .card-title {
            color: $black;
            font-size: 1rem;
            transition: none !important;

            @include media-breakpoint-up(sm) {
                font-size: $h6-font-size;
            }
        }

        .card-description {
            font-size: 13px;
            @include media-breakpoint-up(sm) {
                font-size: 16px;
            }
        }
    }

    .card-buttons {
        display: none;
    }
}

// card in slider
.owl-carousel {
	&.slider {
		.card {
			box-shadow: none;
			border: 1px solid $card-border-color;
		}
	}
}
