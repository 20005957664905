// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.75rem;
		font-family: $headings-font-family;
		font-size: $h6-font-size;
		margin-bottom: 1.25rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $secondary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.75rem;
		font-family: $headings-font-family;
		font-size: $h6-font-size;
		margin-bottom: 1.25rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $secondary;
			font-weight: 400;
		}
	}
}

// usps list 
ul.usps-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.25rem;

	li {
		display: flex;
		align-items: center;
		margin-bottom: 1.25rem;
		font-family: $headings-font-family;
		font-size: $h6-font-size;

		i {
			font-size: 1.125rem;
			color: $green;
			margin-right: 10px;
		}
	}
}