// mini-sab
.mini-sab {
	background-color: $white;
	margin-top: 0 !important;
	z-index: 4 !important;

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			flex: 1;
			z-index: 100;
			position: relative;
			margin-top: -80px;
			margin-left: 30px;
			margin-right: 30px;
			border-radius: 30px;
			background: $green-dark;
			box-shadow: 0 0 20px rgba($black, 0.29);
			padding: 2.25rem 1.25rem;
			margin-bottom: auto;
			
			@include media-breakpoint-up(sm) {
				padding: .5rem 1.25rem 1rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: -110px;
				border-radius: 15px;
			}

			.column {
				justify-content: center;
				padding-left: 15px !important;
				padding-right: 15px !important;
				margin-bottom: 0;

				&.one {
					@include make-col(12);
					@extend .text-center;

					// @include media-breakpoint-up(sm) {
					// }
					// @include media-breakpoint-up(lg) {
					// 	@include make-col(2)
					// }
				}

				&.two {
					@include make-col(12);

					// @include media-breakpoint-up(sm) {
					// 	@include make-col(9)
					// }
					// @include media-breakpoint-up(lg) {
					// 	@include make-col(10)
					// }
				}
			}

			.title {
				@include headings-style {
					color: $white;
				}

				margin-bottom: 1rem;

				@include media-breakpoint-up(lg) {
					@include headings-style {
						margin: 0;
					}
				}
			}
		}
	}
}
