// Hide mini-sab
&.accommodation-category-detail,
&.accommodation-search-book,
&.accommodation-book {
    .mini-sab {
        display: none;
    }
}

&.accommodation-detail,
&.accommodation-object-detail {

    .main {
        &::after {
            content: '';
            height: 150px !important;
            display: block;
            background-color: $green-light;
        }
    }

    .lead-section {

        .container-one-column {
            .container-holder {
                @include media-breakpoint-up(lg) {
                    max-width: $max-content-size;
                    margin: 0 auto;
                }

                @include media-breakpoint-up(xxl) {
                    max-width: 94%;
                }
            }
        }

        @include media-breakpoint-only(lg) {
            padding-top: 11rem;
        }

        @include media-breakpoint-down(md) {
            padding-top: 6rem;
        }

        .title {
            h1 {
                font-size: $h2-font-size;
                margin-bottom: 0;
            }
        }

        .properties {
            justify-content: flex-start !important;
            margin-left: -15px;
            margin-bottom: 2.375rem;
            .icon {
                color: $orange-dark;
                i {
                    font-weight: 300;
                }
            }

            .label {
                font-family: $headings-font-family;
                font-size: 1rem;
                color: $black;
            }
        }
    }

    .float-content {
        margin-top: calc( 9rem + 5rem) !important;
        
        @include media-breakpoint-up(lg) {
            margin-top: calc( 7rem + 5rem) !important;
        }
    }

    .gallery-section {
        padding-top: 10px;
    }

    .content-section {
        @extend .bg-light;
        @extend .float-content;
        @extend .top-curve;
        // @extend .bottom-curve;

        .column.one {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -16rem);

            @include media-breakpoint-up(sm) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -14rem );
            }
    
            @include media-breakpoint-up(lg) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -12rem );
            }
        }

        .column.two {
            @include media-breakpoint-up(lg) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -12rem );
            }
        }

        .collection {
            margin-top: 0 !important;
        }

        .container-one-column {
            .container-holder {
                max-width: $max-content-size;
                margin: 0 auto;
            }
        }

        // ul {
        //     list-style: none;
        //     @include make-row();
        //     @extend .mx-0;
        //     li {
        //         @include make-col(12);
        //         @include media-breakpoint-up(sm) {
        //             @include make-col(6);
        //         }

        //         &::before {
        //             color: $green;
        //         }

        //         .icon {
        //             color: $orange-dark;
        //             margin-right: 5px;
        //             i {
        //                 font-weight: 300;
        //             }
        //         }

        //         .title {
        //             font-family: $headings-font-family;
        //         }
    
        //     }
        // }
    }

    .booking-section {
        h2 {
            @extend .h3;
        }
    }


    .footer {
        margin-top: 2rem !important;
    }
}

&.accommodation-category-detail,
&.accommodation-overview {

    .bundle-overview-section {
        padding-bottom: 230px;
    }

    .footer {
        margin-top: 2rem !important;
    }
}

&.accommodation-category-overview {
    .bundle-overview-section {
        padding-bottom: 230px;
    }

    .footer {
        margin-top: 2rem !important;
    }
}

&.accommodation-search-book,
&.accommodation-book {

    .float-content {
        padding-bottom: 180px;

        margin-top: calc( 25rem + 5rem) !important;
        
        @include media-breakpoint-up(md) {
            margin-top: calc( 17rem + 5rem) !important;
        }
        
        .search-book {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -30rem);
            
            @include media-breakpoint-up(sm) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -28rem );
            }
            
            @include media-breakpoint-up(md) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -20rem );
            }
        }
    }

    .footer {
        margin-top: 2rem !important;
    }
}

&.accommodation-search-book {
    .container {
        .container-holder {
            @include media-breakpoint-down(lg) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}

&.accommodation-book {
    .float-content {

        margin-top: calc( 14rem + 5rem) !important;
        
        .book {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -16rem);
        }

        .container {
            .container-holder {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}