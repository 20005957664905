&.timeslot-overview {

    // datepicker
    .datepicker {
        .next, 
        .prev {
            font-size: 0;

            &::after {
                font-family: $font-awesome;
                font-weight: 900;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-size: 1rem;
            }
        }

        .next {
            &::after {
                content: "\f054";
            }
        }

        .prev {
            &::after {
                content: "\f053";
            }
        }
    }

    .timeslot-item,
    .timeslot-datepicker {
        border-radius: 0 0 15px 15px;
        border: none;
        box-shadow: 0 0 26px rgba($black, .25);
        @include prefix(transition, .3s);

        &:hover {
            @include prefix(transform, translateY(-.5rem));
            box-shadow: 0 0 30px rgba($black, .5);
        }
    }

    .timeslot-item {
        &-navigation {
            &-btn {
                padding: 5px 10px !important;

                &::after {
                    display: none !important;
                }
            }

            &-duration {
                font-size: .875rem;
            }
        }

        &-days {
            .timeslot-item-day {
                .timeslot-item-day-title-holder,
                .timeslot-item-day-times-holder {
                    @include make-col(6);
                }
            }
        }
    }

    .float-content {
        .timeslot-overview {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -16rem);

            @include media-breakpoint-up(sm) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -14rem );
            }
    
            @include media-breakpoint-up(lg) {
                margin-top: calc( ( var(--w3-round-height) * -1 ) + -12rem );
            }
        }
    }

    .bundle-overview-section {
        padding-bottom: 230px;
    }

    .footer {
        margin-top: 2rem !important;
    }
}