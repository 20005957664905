// header
.header {
	z-index: 998;
	transition: 0.5s;
	padding: 1.5rem 0;

	@include media-breakpoint-down(md) {
		padding: 12px 0;
	}

	&.sticky {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		box-shadow: $shadow;
		padding: .5rem 0;

		// logo
		.logo {
			padding: 20px 46px 20px 30px;
			top: 5px;

			img {
				width: 110px;
			}
		}

		// news-ticker
		.news-container {
			top: -.5rem;
		}
	}

	a {
		text-decoration: none !important;
	}

	.container,
	.container-fluid {
		@include media-breakpoint-up(lg) {
			padding-left: 45px;
			padding-right: 45px;
		}
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// news-ticker
	.news-container {
		background: $white;
		top: -1.5rem;
		left: 0;
		position: relative;
		overflow: hidden;
		width: 100% !important;
		transition: 0.5s;

		@include media-breakpoint-down(md) {
			top: -12px;
		}

		@include media-breakpoint-down(lg) {
			.column {
				padding: unset;
			}
		}

		div.news-ticker {
			display: flex;
			flex-flow: row nowrap;

			&:hover ul {
				animation-play-state: paused;
			}

			ul {
				height: 40px;
				animation: scroll 25s linear infinite;
				transition: 1s ease-out;
				float: left;
				display: flex !important;
				flex-flow: row nowrap !important;
				align-items: center;
				gap: 5rem;
				width: max-content;
				padding-left: 5rem;

				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline !important;
						text-underline-offset: 7px;
					}
				}
				li {
					min-width: fit-content;
					display: flex;
					align-items: center;
					font-size: 13px;
					font-weight: 500;
					b {
						font-weight: 700;
					}

					&::after {
						content: "\f005 \f005 \f005 \f005 \f005";
						font-family: $font-awesome;
						color: $primary;
						font-size: 12px;
						font-weight: 600;
						margin-left: 3rem;
					}
				}

				@keyframes scroll {
					0% {
						transform: translateX(0%);
					}

					100% {
						transform: translateX(-50%);
					}
				}
			}
		}
	}


	// menu-toggle
	.menu-toggle {
		order: 2;
		display: flex;
		margin-right: 2rem;
		margin-left: auto;

		.navbar-toggler {
			display: block !important;
			padding: 0;
			font-size: 1.5rem;
			outline: none;

			.navbar-toggler-icon {
				text-align: center;
				@include icon-size(30px);
				color: $green-dark;

				.fa-times {
					display: none;
				}
			}
		}

		&.clicked {
			z-index: 10;
			.navbar-toggler {
				.navbar-toggler-icon {
					.fa-times {
						display: block;
					}
					.fa-bars {
						display: none;
					}
				}
			}
		}
	}

	// logo
	.logo {
		padding: 20px 46px 20px 20px;
		background-color: $white;
		box-shadow: 0 0 20px rgba($black, 0.29);
		position: absolute;
		top: 4px;
		left: 0;
		border-radius: 0 999px 999px 0;
		z-index: 2;
		text-align: start;
		transition: .3s;

		@include media-breakpoint-up(lg) {
			padding: 36px 76px 36px 36px;
			top: -4px;
			left: -40px;
		}

		a {
			@extend .d-block;

			img {
				transition: .3s;
				width: 98px;

				@media (max-width: 360px) {
					width: 70px;
				}

				@include media-breakpoint-up(lg) {
					width: 165px;
				}
			}
		}
	}

	// menu
	.menu {
		order: 1;

		&#default_menu {
			@include media-breakpoint-up(lg) {
				display: flex !important;
				flex-basis: auto;
			}

			justify-content: flex-end;
			margin-right: 1rem;
			.navbar-nav {
				flex-direction: row;

				.nav-link {
					padding-left: 1rem !important;
					padding-right: 1rem !important;
				}
			}
		}

		&#hamburger_menu {
			@include media-breakpoint-up(md) {
				transition: all 0.5s ease;
				justify-content: flex-start;
				display: flex !important;
				position: fixed;
				inset: 0;
				background-color: rgba($black, 0.25);
				width: 100%;
				height: 100% !important;
				z-index: -1;
				opacity: 0;
				visibility: hidden;
			}

			.navbar-nav {
				@include media-breakpoint-up(md) {
					transform: translateX(-100%);
					background-color: $white;
					flex-direction: column;
					width: 300px;
					padding: 2rem;
					height: 100% !important;
					justify-content: center;
					transition: all 0.5s ease;
					font-size: $h4-font-size;
					box-shadow: 6px 0 12px rgba($black, 0.15);
				}
			}

			&.show {
				@include media-breakpoint-up(md) {
					opacity: 1;
					visibility: visible;
					z-index: 9;

					.navbar-nav {
						transform: translateX(0);
					}
				}
			}

			@include media-breakpoint-down(sm) {
				order: 3;
				.navbar-nav {
					padding-top: 110px;
				}
			}
		}

		.navbar-nav {
			.dropdown-menu {
				padding-left: 1rem;
				position: static !important;
				transform: translateX(0) !important;
				box-shadow: none;
				border: none;
				background-color: unset;

				.nav-item {
					.nav-link {
						@include media-breakpoint-up(md) {
							font-size: $h5-font-size;
							font-weight: 500;
						}
					}
				}

				&::before,
				&::after {
					display: none;
				}
			}

			.nav-item {
				.nav-link {
					color: $green-dark !important;
					position: relative;
					font-weight: 500;
				}

				&.active,
				&:hover {
					> .nav-link {
						color: $black !important;

						.menu-title {
							&::before {
								opacity: 1;
								bottom: -4px;
							}
						}
					}
				}
			}

			> .nav-item {
				> .nav-link {
					.menu-title {
						position: relative;
						&::before {
							content: "";
							position: absolute;
							bottom: -10px;
							width: 100%;
							border-bottom: 1px solid $black;
							opacity: 0;
							transition: 0.3s;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		order: 3;
		margin-left: 1rem;

		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			padding-right: 15px;
			margin-top: 10px;
		}

		.navbar-nav.language {
			@include media-breakpoint-down(sm) {
				justify-content: flex-end;
			}

			.nav-item {
				&.active {
					border: 2px solid $secondary;
					border-radius: 99px;
				}
			}
		}
	}
}
