.collection.grid {
    .grid-items {
        .item {
            // on mobile
            @include make-col(12);

            // on tablets
            @include media-breakpoint-up(md) {
                margin: 0 0 40px 0;
                @include make-col(6);
            }

            // on large screens
            @include media-breakpoint-up(xl) {
                @include make-col(4);
            }
        }
    }
    &.two-columns {
        .grid-items {
            .item {
                // on mobile
                @include make-col(12);

                // on tablets and above
                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                // on desktops and above
                @include media-breakpoint-up(xl) {
                    @include make-col(3);
                }
            }
        }
    }
}

.float-content {
    .collection,
    .image-collection {
        margin-top: calc( ( var(--w3-round-height) * -1 ) + -16rem);

        @include media-breakpoint-up(sm) {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -14rem );
        }

        @include media-breakpoint-up(lg) {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -12rem );
        }
    }
}

.collection.grid.icons-collection {
    .grid-items {
        .item {
            @include make-col(6);
            padding-left: 10px !important;
            padding-right: 10px !important;

            @include media-breakpoint-up(md) {
                @include make-col(4);
            }
        }
    }
}
