// new base file - january 2022

#TommyBookingSupport { font-size: 16px;}

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav {
  background-color: $primary;
  float: unset;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > li > a {
  color: #777;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > li > a:hover {
  color: $black;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
  background-color: $orange-dark;
  color: $primary;
}
#TommyBookingSupport .tbs-btn-info {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info:hover {
  background-color: darken($secondary, 20%);
  border-color: darken($secondary, 20%);
  color: $white;
}
#TommyBookingSupport .tbs-btn-success {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success:hover {
  background-color: darken($secondary, 20%);
  border-color: darken($secondary, 20%);
  color: $white;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $orange-dark !important;
}

.tbs-plattegrond .mapplic-pin.pin,
.tbs-plattegrond .mapplic-pin.green {
  color: #00FF00 !important;
}

.tbs-plattegrond .mapplic-pin.selected {
  color: #EDE900 !important;
}

.tbs-plattegrond .mapplic-pin.pin-red {
  color: #F13742 !important;
}

// manually include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid { padding: 0; }
#TommyBookingSupport .tbs-navbar-collapse { padding: 0; }
#TommyBookingSupport .tbs-navbar-nav>li>a { padding-top: 10px; padding-bottom: 10px; }

#TommyBookingSupport .tbs-navbar { min-height: auto; overflow: hidden; }
#TommyBookingSupport * a { text-decoration: none; }
#TommyBookingSupport .tbs-legenda { background: none; border: none; }

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row.tbs_overig,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2)  {
    padding: 1.5rem 1rem;
    margin: 20px 0;
    background-color: #FFF; // custom
	  border: 1px solid #FFF; // custom
    border-radius: 15px;
    box-shadow: 0 0 26px rgba($black, .25);
}
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon { margin: 33px 0; padding: 20px; }
#TommyBookingSupport .tbs_overlay-mask { border-radius: 5px; }

#TommyBookingSupport .tbs-calendar { margin: 20px 0; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar { cursor: pointer; transition: all 0.5s ease; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover { opacity: 0.8; }
#TommyBookingSupport .tbs-calendar table > thead > tr > th { border: none; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { color: #fff; background-color: #105251; }

#TommyBookingSupport .tbs-row h2 { margin-bottom: 0; font-size: 24px; }  // custom
#TommyBookingSupport .tbs-row h4 { color: $body-color; font-size: $font-size-base; font-weight: $font-weight-base; font-family: $font-family-base; } // custom
#TommyBookingSupport .tbs-btn-block+.tbs-btn-block { margin: 0; color: $primary; }
#TommyBookingSupport .tbs_overzicht .tbs-row h2 { margin-bottom: 15px; }

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row { margin-top: 15px; }

#TommyBookingSupport .tbs-alert-danger { color: #E91E63; background-color: rgba(233, 30, 99, 0.09);  border: none; margin: 0 15px 15px;  padding: 10px 35px 10px 10px; }
#TommyBookingSupport .tbs-has-error .tbs-form-control { border-color: #a94442 !important; }
#TommyBookingSupport textarea.tbs-form-control { height: 200px; }

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row { margin-bottom: 15px; }
#TommyBookingSupport .tbs_kassabon .tbs-row .tbs-row { margin-left: 0; margin-right: 0; }
#TommyBookingSupport .tbs_kassabon .tbs_kassabon_overzicht .tbs-specificaties .tbs-col-xs-12 { padding: 0; }
#TommyBookingSupport .tbs-label { font-size: 100%; margin-right: 5px; }
#TommyBookingSupport .tbs_kassabon .tbs-hr { border-top: 1px solid #e7e7e7; }
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate { font-weight: 400; }
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount { font-size: 32px; line-height: 50px; }
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row { margin: 0 0 15px 0; }
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row.hide { display: none; }

#TommyBookingSupport .tbs-row .tbs-page-overzicht div.tbs_av input#tbs_av {
  margin-top: -6px;
}

#TommyBookingSupport .tbs_overig_overzicht .tbs-row { margin-left: 0; margin-right: 0; }

@include media-breakpoint-down(lg) {
  #TommyBookingSupport .tbs_overig .tbs-row .tbs-col-xs-6.tbs-naam {
    width: 100%;
  }
  #TommyBookingSupport .tbs_overig .tbs-row .tbs-col-xs-6 {
    width: 100%;
  }
}

#TommyBookingSupport .tbs-page-overzicht .tbs-translate a,
#TommyBookingSupport .tbs_persoonsgegevens_overzicht a.tbs-translate {
  color: $orange-dark;
}

.tbs_kassabon .tbs_kassabon_toggle i {
  display: inline-block !important;
  transform: rotate(90deg);
  margin-left: 10px;
}


@include media-breakpoint-down(md) {
  .tbs_kassabon .tbs_totaal_overzicht .tbs-totaal {
    background-color: $green;
  }
}

/* resonsive mobile */
@media (max-width: 767px) {

    #TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
    #TommyBookingSupport .tbs-calendarNext span.tbs-translate { display: none; }

}
