// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		padding: 3.5rem 0;
		color: $black-1;
		@include headings-style {
			color: $black;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 3.5rem 0;
	font-size: $h6-font-size;

	.container-one-column {
		.container-holder {
			@include media-breakpoint-up(lg) {
				
				
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				text-align: center;

				&.one {
					@include make-col(12);
				}
				&.two {
					@include make-col(12);

					.page-block.wysiwyg {
						@include media-breakpoint-up(md) {
							height: 0;
						}
					}

					img {

						@include media-breakpoint-up(md) {
							position: absolute;
							right: -54px;
							top: -28rem;
						}

						@include media-breakpoint-up(lg) {
							top: -26rem;
						}

						@include media-breakpoint-up(xl) {
							top: -20rem;
							right: -75px;
						}

					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: .5rem;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}

	.title {
		@include headings-style {
			margin-bottom: 1rem;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	padding: 3.5rem 0;
}

.eyecatcher.float-caption + .content-section {
	padding-top: 13rem;

	@include media-breakpoint-up(lg) {
		padding-top: 10.875rem;
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3.5rem 0;

	&.float-content {
		padding-top: 0;
	}
}

.eyecatcher.float-caption + .collection-section {
	padding-top: 20rem;

	@include media-breakpoint-up(lg) {
		padding-top: 10.875rem;
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3.5rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3.5rem 0;
}

// gallery-section
// =========================================================================
// .gallery-section {
// 	margin: 3rem 0;
// }

// booking-section
// =========================================================================
.booking-section {
	padding: 3.5rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3.5rem 0;
}

// book-button
// =========================================================================
section.book-button {
	margin-top: 0 !important;

	.container-fluid {
		@include media-breakpoint-up(lg) {
			padding-left: 45px;
			padding-right: 45px;
		}
	}

	div.link {
		text-align: end;
		margin-top: -100px;

		a {
			@extend .btn;
			background-color: $green-dark;
			color: $white;
			font-family: $headings-font-family;
			padding: 8px 10px 8px 36px !important;
			font-size: 1.5rem;

			&::after {
				width: 48px;
				height: 48px;
				background-color: $green;
				border-radius: 50%;
				line-height: 48px !important;
				transition: none !important;
				margin-left: 30px !important;
			}

			&:hover {
				color: $green;
				&::after {
					color: $green-dark;
					@include prefix(transform, translateX(0) !important);
				}
			}
		}
	}
}

// usps section
// =========================================================================
.usps-section {
	padding-top: 3.5rem;
}