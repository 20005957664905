&.service-detail {
    .lead-section {
        padding: 0;

        .title {
            h1 {
                font-size: $h2-font-size;
            }
        }
    }

    .content-section {
        padding-bottom: 230px;
        .container-one-column {
            .container-holder {
                max-width: $max-content-size;
                margin: 0 auto;
            }
        }
    }

    .footer {
        margin-top: 2rem !important;
    }
}

&.service-category-detail {

    .lead-section {

        .container-one-column {
            .container-holder {
                @include media-breakpoint-up(lg) {
                    max-width: unset;
                    margin: 0 -15px;
                }

                @include media-breakpoint-down(lg) {
                    margin: 0 15px;
                }
            }
        }
    }

    .bundle-detail-section:not(:has(+.bundle-overview-section)) {
        @extend .bg-light;
        @extend .top-curve;
    }

    .bundle-detail-section,
    .bundle-overview-section {
        padding-bottom: 230px !important;
    }

    .bundle-detail-section:has(+.bundle-overview-section) {
        padding-bottom: 0 !important;
    }

    .footer {
        margin-top: 2rem !important;
    }
}

&.service-overview {

    // lead-section
    // =========================================================================
    .lead-section {

        .container-one-column {
            .container-holder {
                @include media-breakpoint-up(lg) {
                    max-width: unset;
                    margin: 0 -15px;
                }

                @include media-breakpoint-down(lg) {
                    margin: 0 15px;
                }
            }
        }
    }

    .bundle-overview-section {
        padding-bottom: 230px;
    }

    .footer {
        margin-top: 2rem !important;
    }
}
