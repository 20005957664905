// footer
.footer {
    background-color: $green-dark;
    margin-top: 230px !important;

    .footer-outro {
        transform: translateY( -50px );


        .footer-logo {
            text-align: center;
            margin-top: -108px;
        }
    }

    .footer-sitelinks {

        &, a {
            color: $white;
        }

        a:hover {
            color: $orange-dark;
        }

        @include headings-style {
            color: $white;
        }
        
        .container-one-column {
            .column {
                @include media-breakpoint-up(lg) {
                    @include make-col(8);
                }
            }
        }

        .container-four-columns {
            margin-top: 60px;
            @include media-breakpoint-up(lg) {
                margin-top: 80px;
            }

            .column {
                &.one {
                    @include media-breakpoint-up(lg) {
                        @include make-col(4);
                    }
                }

                &.two {
                    @include media-breakpoint-down(xs) {
                        @include make-col(4, 10);
                    }
                }

                &.three {
                    @include media-breakpoint-down(xs) {
                        @include make-col(6, 10);
                    }
                }

                &.four {
                    @include make-col(6);
                }

                &.two,
                &.three,
                &.four {

                    @include media-breakpoint-up(md) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(2, 10);
                    }
                }
            }
        }

        .footer-text {
            h6 {
                font-weight: 700;
            }
        }

        .footer-logolink {
            .list {
                flex-flow: row wrap;
                gap: 1rem;
                .link {
                    @extend .btn;
                    @extend .btn-white;
                    padding-left: 2.25rem !important;
                    padding-right: 2.25rem !important;
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    max-width: max-content;

                    i {
                        font-size: 14px;
                        margin-left: 10px;
                        transition: .3s;
                    }

                    &:hover {
                        i {
                            @include prefix(transform, translateX(10px));
                        }
                    }

                    &::after {
                        display: none !important;
                    }
                }
            }
        }

        ul.footer-social {
            flex-wrap: nowrap !important;
            @include media-breakpoint-down(sm) {
                margin-top: 1.75rem;
                margin-left: 0;
            }
            column-gap: 1rem;
            margin-left: 1rem;

            a {
                @include icon-size(30px);
                display: block;
                font-size: 30px;
                text-decoration: none;
                text-align: center;
            }

            .list-item-title {
                display: none;
            }
        }

        .footer-list {
            h5 {
                font-size: $h6-font-size;
                font-family: $font-family-base;
                font-weight: bold;
            }

            ul {
                margin-bottom: 1rem;
            }
        }
    }

    .footer-copyright {
        
        padding: 45px 0 30px;
        @include media-breakpoint-up(lg) {
            padding: 100px 0 30px;
        }

        .list {
            column-gap: 20px;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: center;
            }

            .list-item {
                margin-bottom: .5rem;

                .link {
                    color: $white;
                    text-decoration: none;
                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                }
            }

        }

    }

}
