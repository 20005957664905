// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 385px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 340px;
	max-height: 500px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
		max-height: 600px;
	}
}

&:not(.home) {
	.eyecatcher .owl-carousel {
		.owl-nav {
			display: none;
		}
	}
}

.eyecatcher.large {
	.owl-carousel {
		--w3-owl-caption-offset: 0;
		.item {
			.owl-container {
				padding-left: 45px;
				padding-right: 45px;
			}

			.owl-caption {
				@include prefix(transform, translateY(-100px));
				@include prefix(transition, all 1s ease-out);
				opacity: 0;

				.owl-title {
					font-size: 2.25rem;
					@include media-breakpoint-up(lg) {
						font-size: 3rem;
					}
				}
			}
		}

		.owl-item.active,
		.owl-item.animated {
			.item {
				.owl-caption {
					@include prefix(
						transform,
						translateY(var(--w3-owl-caption-offset))
					);
					opacity: 1;
				}
			}
		}

		.owl-nav {
			.owl-prev {
				@include media-breakpoint-up(lg) {
					left: 40px;
				}
			}

			.owl-next {
				@include media-breakpoint-up(lg) {
					right: 40px;
				}
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
}

// Eyecatcher - Float caption
.eyecatcher.float-caption {
	padding: 3.5rem 0;
	background-color: $green-light;

	.owl-carousel.float-caption {
		--w3-owl-caption-offset: 50%;

		@include media-breakpoint-up(lg) {
			--w3-owl-caption-offset: 40px;
		}
		z-index: 4;

		.item {
			align-items: flex-end;
			margin-bottom: var(--w3-owl-caption-offset);
			@media (max-width: 360px) {
				.owl-container {
					padding-left: 0;
					padding-right: 0;
				}
			}
			.owl-caption {
				@include prefix(transform, translateY(-50px));

				background-color: $green-dark;
				border-radius: 15px;
				padding: 1.875rem 1.25rem 3rem;
				width: 340px;
				margin-left: auto;
				margin-right: auto;
				text-align: start;
				@media (max-width: 360px) {
					width: 310px;
					padding: 1.875rem 0.75rem 2rem;
				}
				@include media-breakpoint-up(lg) {
					@include prefix(transform, translateY(-100px));
					width: 530px;
					padding: 1.5rem 2.5rem 1.875rem;
					margin-right: 0;
				}

				.owl-title {
					font-weight: 400;
					font-size: 1.5rem;
					margin-bottom: 1.25rem;
					@include media-breakpoint-up(lg) {
						font-size: 1.875rem;
					}
				}

				.owl-description {
					@include media-breakpoint-up(lg) {
						font-size: 1.25rem;
					}
				}

				.owl-btn {
					padding: 0;
					background-color: unset;
					border: none;
					color: $white;
				}
			}
		}

		.owl-nav {
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}
}
