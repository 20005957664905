// container
.container {

	// .container-holder {
	// 	@include media-breakpoint-down(lg) {
	// 		margin-left: 15px;
	// 		margin-right: 15px;
	// 	}
	// }

	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// backgrounds
.bg-light {
	background-color: $green-light !important;
}

// custom headings
.custom-h1 {
	font-size: $font-size-base * 2.25;
	@include media-breakpoint-up(lg) {
		font-size: $font-size-base * 3;
	}
}

// .float-content
.float-content {
    margin-top: 17rem !important;
}

// default-card
// .default-card {}
