// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #B4CD2B;
$green-light: #F5F4DF;
$green-dark: #6D725A;
$orange: #F1AA23;
$orange-dark: #DA6D25;
$blue: #488ddb;
$blue-dark: #044389;
$white: #fff;
$black: #000;
$black-1: #2D2D2C;

// theme-colors
$primary: $orange-dark;
$secondary: $green;

// body
$body-bg: $white;
$body-color: $black-1;

// links
$link-color: $orange-dark;
$link-decoration: underline;
$link-hover-color: $orange;

// typography
// @import url('https://fonts.googleapis.com/css2?family=Fresca:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300;400;500;700&display=swap');

$font-family-primary: "Radio Canada", sans-serif;
$font-family-secondary: "Amaranth", sans-serif;
// $font-family-secondary: "Fresca", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3.75; // 60px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $black;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

// sizes
$max-content-size: 675px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;
