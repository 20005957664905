&.faq-overview {

    .faq-overview {
        margin-top: calc( ( var(--w3-round-height) * -1 ) + -16rem);

        @include media-breakpoint-up(lg) {
            margin-top: calc( ( var(--w3-round-height) * -1 ) + -14rem );
        }

        background-color: $white;
        box-shadow: 0 0 26px rgba($black, .25);
        border-radius: 0 0 15px 15px;
        padding: 1.5rem 1.25rem 3.5rem;

        .faq-icon {
            left: auto;
            right: 0;
            color: $orange-dark;
        }

        .faq-title {
            padding: 10px 50px 10px 10px;

            h2 {
                color: $black;
            }

            &:hover {
                .faq-icon {
                    color: $orange; 
                }
            }
        }

        .faq-category {
            .faq-items {
                .faq-item {
                    .faq-question {
                        padding: 10px 50px 10px 10px;
                        
                        h3 {
                            color: $black;
                        }

                        &:hover {
                            .faq-icon {
                                color: $orange; 
                            }
                        }
                    }

                    .faq-answer {
                        padding: 10px;
                    }
                }
            }
        }
    }

    .bundle-overview-section {
        padding-bottom: 230px;

        .container {
            .container-holder {
                max-width: $max-content-size;
                margin: auto;
            }
        }
    }

    .footer {
        margin-top: 2rem !important;
    }
}