&.one-column-page,
&.disclaimer,
&.privacy-statement {
    .lead-section {
        padding-bottom: 0;

        .page-title {
            h1 {
                font-size: $h2-font-size;
            }
        }
    }

    .content-section {
        .container-one-column {
            .container-holder {
                @include media-breakpoint-up(lg) {
                    max-width: $max-content-size;
                    margin: 0 auto;
                }
            }
        }
    }
}