&.contact-page {

    .float-content {
        margin-top: 10rem !important;
    }

    .lead-section {
        padding-bottom: 0;

        .container {
            .container-holder {
                max-width: 100%;
                margin-left: 15px;
                margin-right: 15px;
            }
        }


        .page-title {
            h1 {
                font-size: $h2-font-size;
            }
        }
    }

    .content-section {
        padding-bottom: 230px;

        @include media-breakpoint-down(md) {
            margin-top: 8rem !important;
            padding-top: 0 !important;
        }

        .container {
            .container-holder {
                

                @include media-breakpoint-up(lg) {
                    margin-top: calc( ( var(--w3-round-height) * -1 ) + -12rem );
                }

                .column {
                    
                    @include media-breakpoint-up(lg) {
                        margin-top: calc( ( var(--w3-round-height) * -1 ) + 18rem);
                    }
                }
            }
        }
    }

    .footer {
        margin-top: 2rem !important;
    }
}